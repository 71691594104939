import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import SampleForm from '../components/Form'
import moneydialogue from '../img/money-dialogue.svg'
import swish from '../img/swish.png'
import swishw from '../img/swish-white.png'
// import empben from '../img/employer-benefits-icon.png'
import whitebranch from '../img/large-branch-white.png'
// import leaf from '../img/03-leaf.svg'
// import plant from '../img/001-plant.svg'
// import plants from '../img/02-plant-1.svg'
import plantsoil from '../img/05-plant-2.svg'
// import cfp from '../img/cfp-logo-plaque-black-outline.jpg'

class WhoWeArePage extends React.Component {
  render() {
    const siteTitle = 'Who We Are'
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div class="atf-about">
          <div class="container">
            <div class="col-md-12">
              <h1 class="atf-h text-center text-white">
                <strong>Who We Are</strong>
              </h1>
            </div>
          </div>
        </div>
        <div class="about-us">
          <div class="bg text-center">
            <img alt="plantsoil" src={plantsoil} />
          </div>
          <div class="container-fluid">
            <h1 class="section-h text-center">
              <strong>What is Journey Financial Services Inc?</strong>
            </h1>
            <div class="row">
              <div class="col-md-6 serv-table ">
                <h1 class="service-h">
                  <strong>Our Vision</strong>
                </h1>
                <div class="swish text-center">
                  <img alt="swish" src={swish} className="mb-3" />
                </div>
                <p class="about-p text-center">
                  To become the ‘go-to’ partner in creating meaningful financial
                  family legacies
                </p>

                <h1 class="service-h">Our Mission</h1>
                <div class="swish text-center">
                  <img alt="swish" src={swish} className="mb-3" />
                </div>
                <p class="about-p text-center">
                  To master our guidance gifts leading self and others to more
                  meaningful and financially independent lives.
                </p>
              </div>

              <div class="col-md-6 serv-table">
                <h1 class="service-h">
                  <strong>Our Differentiator</strong>
                </h1>
                <div class="swish text-center">
                  <img alt="swish" src={swish} className="mb-3" />
                </div>

                <p class="about-p text-center">
                  CREDENTIALS <br /> - Series 7 & 66,(held with LPL Financial) ,
                  CFP® professional, Insurance{' '}
                </p>

                <p class="about-p text-center">
                  EDUCATION <br />- Math & Economics Degrees{' '}
                </p>

                <p class="about-p text-center">
                  EXPERIENCED <br />- 30+ yrs in the financial services
                  industry; Entrepreneur{' '}
                </p>

                <p class="about-p text-center">
                  COLLABORATION <br />- Tax Planning & Estate Planning{' '}
                </p>

                <p class="about-p text-center">
                  VALUES-DRIVEN <br />– Competence, Reliability, Gratitude,
                  Transparency{' '}
                </p>

                <p class="about-p text-center">
                  CREATIVE <br />– Sewing, baking, decorating{' '}
                </p>

                <p class="about-p text-center">
                  ACTIVE/HEALTH CONSCIOUS <br />– half marathon runner{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="service-section2">
          <div class="container">
            <div class="col-md-12">
              <div class="bg text-center">
                <img alt="whitebranch" src={whitebranch} />
              </div>
              <div class="container">
                <div class="row">
                  <div class="col-md-6">
                    <h1 class="service-h text-white">Our Values</h1>
                    <div class="swish text-center">
                      <img alt="swishw" src={swishw} />
                    </div>
                    <p class="atf-p text-white bg-1">
                      Comprehensive financial planning
                    </p>
                    <p class="atf-p text-white bg-1">
                      Continuous Education (owners, staff and clients)
                    </p>

                    <p class="atf-p text-white bg-1">
                      Friendly positive environment
                    </p>
                  </div>
                  <div class="col-md-6">
                    <h1 class="service-h text-white">Our Promise</h1>
                    <div class="swish text-center">
                      <img alt="swishw" src={swishw} />
                    </div>
                    <p class="atf-p text-white bg-1">To be transparent</p>
                    <p class="atf-p text-white bg-1">To be responsive</p>
                    <p class="atf-p text-white bg-1">
                      To understand your needs
                    </p>
                    <p class="atf-p text-white bg-1">
                      To offer comprehensive investment products
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<div class="our-values">
    
</div> */}
        <div id="newsletter" class="cont">
          <div class="container">
            <div class="col-md-12">
              <div class="bg text-center">
                <img alt="moneydialogue" src={moneydialogue} />
              </div>
              <h1 class="section-h text-center text-white">
                <strong>
                  Get advice from Journey Financial Services&nbsp;
                </strong>
              </h1>
              <p class="service-p text-white">
                <em>
                  You will automatically be subscribed to our bi-monthly
                  newsletter&nbsp;to receive e-mails&nbsp;filled with articles,
                  advice and financial guidance
                </em>
              </p>
            </div>
          </div>
          <div class="container">
            <SampleForm
              form_name="Newsletter Form"
              form_id="5df865b7676f4567eacd2532"
              form_classname="form-newsletter"
              class="row justify-content-center"
              method="post"
            >
              <div className="form-row">
                <div className="mb-3">
                  <div className="form-group">
                    <input
                      className="form-control c-form"
                      type="text"
                      name="Name"
                      id="full_name"
                    />
                    <label htmlFor="full_name">Enter Your Name</label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <input
                      className="form-control c-form"
                      type="email"
                      name="Email Address"
                      id="email_address"
                    />
                    <label htmlFor="email_address">Email</label>
                  </div>
                  <small class="form-text text-danger">
                    Please enter a correct email address.
                  </small>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <input
                      className="form-control c-form"
                      type="text"
                      name="MailingAddress"
                      id="mailing_address"
                    />
                    <label htmlFor="mailing_address">Mailing Address</label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <input
                      className="form-control c-form"
                      type="text"
                      name="Phone"
                      id="phone"
                    />
                    <label htmlFor="phone">Phone</label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <button
                      className="btn btn-primary bttn float-left c-button"
                      type="submit"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-group">
                    <div className="webriq-recaptcha" />
                  </div>
                </div>
              </div>
            </SampleForm>
          </div>
        </div>
        {/* <SampleForm
            form_name="Newsletter Form"
            form_id="5bcd42f86b63453b251972bc"
            form_classname="form-newsletter"
          >
          <div className="form-row">
          <div className="col-12 mb-3">
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                name="Email Address"
                id="email_address"
              />
              <label htmlFor="email_address">
                Enter Your Email Address
              </label>
            </div>
            <small className="text-dark form-text mt-2">
                We will never share your email with anyone else.
              </small>
          </div>
          <div className="col-12 mb-3">
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                name="Name"
                id="full_name"
              />
              <label htmlFor="full_name">
                Enter Your Name
              </label>
            </div>
          </div>
          <div className="col-12">
            
              <div className="webriq-recaptcha" />
            
          </div>

          <div className="col-12">
            <div className="form-group">
              <button
                className="btn btn-primary hvr-shadow"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
          </SampleForm> */}
      </Layout>
    )
  }
}
export default WhoWeArePage

export const WhoWeArePageQuery = graphql`
  query WhoWeArePageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
